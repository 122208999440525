<template>
    <div>
        <div class="col-12">
            <div class="card mb-4">
                <div class="card-header">
                    <h4 class="card-title">
                        <span>{{RelName}}</span>
                    </h4>
                    <b-col v-if="showFiltro ? false : true ">
                        <div style="float:right; z-index: 3;">             
                            <div class="DivBotaoLadoLado" >
                                <b-button v-b-toggle="'collapse-filtro'" variant="primary">
                                    <span>FILTROS</span>
                                </b-button>
                            </div>
                        </div>
                    </b-col>
                </div>
                <b-collapse visible  id="collapse-filtro">
                    <div class="card-body">
                        <div>
                            <b-row style="gap: 2rem;">
                                <b-col md="6" xl="2" class="mb-1">
                                    <b-form-group
                                    label="Data de:"
                                    >
                                    <b-form-select
                                    v-model="filtrarPor"
                                    :options="BuscarPorOptions">
                                    </b-form-select>
                                    </b-form-group>
                                </b-col>
                                <b-col md="6" xl="3" class="mb-1">
                                    <b-form-group label="Data Inícial:"> 
                                        <b-form-datepicker id="periodoInicio" 
                                        v-model="PeriodoInicio" 
                                        class="mb-2"
                                        placeholder="Periodo" 
                                        :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }"
                                        today-button
                                        reset-button></b-form-datepicker>
                                    </b-form-group>
                                </b-col>

                                <b-col md="6" xl="3" class="mb-1">
                                    <b-form-group label="Data Final:"> 
                                        <b-form-datepicker id="periodoFim" 
                                        v-model="PeriodoFim" 
                                        class="mb-2" 
                                        placeholder="Periodo"
                                        :date-format-options="{
                                            year: 'numeric',
                                            month: 'numeric',
                                            day: 'numeric',
                                        }"
                                        today-button
                                        reset-button></b-form-datepicker>
                                    </b-form-group>
                                </b-col>

                                <b-col md="6" xl="2" class="mb-1">
                                    <b-form-group
                                    label="Nº OS:"
                                    >
                                    <b-form-input
                                        id="NROS"
                                        placeholder="Número da OS"
                                        v-model="NROS"
                                        type="number"
                                    />
                                    </b-form-group>
                                </b-col>

                                
                            </b-row>

                            <b-row style="gap: 2rem;">
                                <b-col md="12" xl="3" class="mb-1">
                                    <b-form-group label="Filtrar por CR:">
                                        <multiselect
                                            v-model="OptionsCR"
                                            placeholder="Todos"
                                            label="name"
                                            track-by="code"
                                            :options="CRopcoes"
                                            :multiple="true"
                                            :taggable="false"
                                            :close-on-select="true"
                                            :option-height="50"
                                            :show-labels="false"                                         
                                        ></multiselect>
                                    </b-form-group>
                                </b-col>

                                <b-col md="12" xl="2" class="mb-1">
                                    <b-form-group label="Filtrar por Status:">
                                        <multiselect
                                            v-model="selectedStatus"
                                            placeholder="Selecione"
                                            label="name"
                                            track-by="code"
                                            :options="OptionsStatus"
                                            :multiple="false"
                                            :taggable="false"
                                            :close-on-select="true"
                                            :option-height="50"
                                            :show-labels="false"   
                                        ></multiselect>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            
                            <div class="DivBotaoLadoLado" >
                                    <b-button
                                        variant="danger"
                                        type="button"
                                        @click="$router.push('../Relatorios')"
                                    ><span>RETORNAR</span>
                                    </b-button>   
                                </div>
                                <div class="DivBotaoLadoLado" >
                                    <b-button v-b-toggle="'collapse-filtro'"
                                        variant="primary"
                                        type="submit"
                                        @click="ConsultarRelatorio"
                                    ><span>CONSULTAR</span>
                                    </b-button>
                                </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>

        <div class="col-lg-12 col-24 ">
                <div class="row ">
                    <div class="col-lg-2 col-md-3 col-6">
                        <div class="card consulta_card">
                            <div class="card-body pb-50" style="position: relative;" @click="ConsultarRelatorioProjeto">
                                <h4 class="fw-bolder mb-1">Projetos
                                    <i class="bi bi-kanban" style="font-size: 3rem; color: green; float:right;"></i>
                                </h4>
                                <h5 class="fw-bolder mb-1">{{TotalProjeto}}</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6">
                        <div class="card consulta_card">
                            <div class="card-body pb-50" style="position: relative;"  @click="ConsultarRelatorioOsRotina">
                                <h4 class="fw-bolder mb-1">OS De Rotina
                                    <i class="bi bi-kanban" style="font-size: 3rem; color: red; float:right;"></i>
                                </h4>
                                <h5 class="fw-bolder mb-1">{{ TotalOsRotina }}</h5>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-2 col-md-3 col-6">
                        <div class="card">
                            <div class="card-body pb-50" style="position: relative;">
                                <h4 class="fw-bolder mb-1">Total OS
                                    <i class="bi bi-bullseye" style="font-size: 3rem; color: orange; float:right;"></i>
                                </h4>
                                <h5 class="fw-bolder mb-1">{{`${TotalOsRotina + TotalProjeto}`}}</h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-3 col-6">
                        <div class="card" style="position: absolute; width: 96%; height: 178%;">
                            <div class="card-body pb-50">
                                <VueApexCharts type="bar" height="85%" :options="chartFatGrupoSetorOptions" :series="series">
                                </VueApexCharts>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row match-height">
                    <div class="col-lg-3 col-md-3 col-6">
                        <div class="card">
                            <div class="card-body pb-50" style="position: relative;">
                                <h4 class="fw-bolder mb-1">OS Finalizadas<span id="infoSpan"> (Mês Atual)</span>
                                    <i class="bi bi-clipboard2-check" style="font-size: 3rem; color: green; float:right;"></i>
                                </h4>
                                <h5 class="fw-bolder mb-1">{{`${OsMesAtual} `}}</h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 col-6">
                        <div class="card">
                            <div class="card-body pb-50" style="position: relative;">
                                <h4 class="fw-bolder mb-1">Total de Horas
                                    <i class="bi bi-stopwatch" style="font-size: 3rem; color: orange; float:right;"></i>
                                </h4>
                                <h5 class="fw-bolder mb-1">{{`${TotalHoras} h  `}}</h5>
                            </div>
                        </div>
                    </div>
                </div>
        </div>

        <div class="col-12">
            <div class="card mb-4">
                <div class="card-body pb-60" >
                    <div>
                        <b-table
                            :items="ListaOs"
                            :fields="ListaOsfields"
                            :sort-by.sync="sortBy"

                            :sort-desc.sync="sortDesc"
                            :current-page="currentPage"
                            :sort-direction="sortDirection"

                            responsive="sm"
                            select-mode = "single"
                            striped
                            hover
                            selectable
                            sticky-header
                            table-class = "font-size11"
                            thead-class = "font-size11"
                            thead-tr-class = "font-size11"
                        >
                        
                            <template #cell(show_details)="row">
                                <b-button size="sm" variant="warning" @click="ConsultarDetalhesOs([row.item.NUMOS])" class="mr-2">
                                    +
                                </b-button>
                            </template>

                            <template #cell(NumOs)="row">
                                {{ row.value }}
                            </template>

                            <template #cell(Solicitante)="row">
                                {{ row.value}}
                            </template>

                            <template #cell(Setor)="row">
                                {{ row.value }}
                            </template>

                            <template #cell(InicioPrevisto)="row">
                                {{ TextFormat(row.value,'date') }}
                            </template>

                            <template #cell(FinalPrevisto)="row">
                                {{ row.value }}
                            </template>

                            <template #cell(Aberta)="row">
                                {{ row.value }}
                            </template>

                            <template #cell(Descricao)="row">
                                {{ row.value }}
                            </template>

                            <template #cell(Prioridade)="row">
                                {{ row.value }}
                            </template>

                            <template #cell(Status)="row">
                                {{ row.value }}
                            </template>
                            
                            <template #cell(Atendente)="row">
                                {{ row.value }}
                            </template>
                        </b-table>
                    </div>
                </div>
            </div>
        </div>

        <div class="Detalhes" id="Conteiner_Detalhes" ref="Detalhes">
            <div class="col-lg-12 col-24">
                <h4 class="card-title">
                    <span>{{`Detalhes OS Número:  ${NumOsDetalhe}`}}</span>
                </h4>
                <hr>
                    <div class="row match-height">

                        <div class="col-lg-4 col-md-3 col-6">
                            <div class="card">
                                <div class="card-body pb-50" style="position: relative;">
                                    <h4 class="fw-bolder mb-1">Início Previsto
                                        <i class="bi bi-calendar-event" style="font-size: 2rem; color: orange; float:right;"></i>
                                    </h4>
                                    <h5 class="fw-bolder mb-1">{{InicioPrevisto}}</h5>
                                </div>
                            </div>
                        </div>     
                        <div class="col-lg-4 col-md-3 col-6">
                            <div class="card">
                                <div class="card-body pb-50" style="position: relative;">
                                    <h4 class="fw-bolder mb-1">Final Previsto
                                        <i class="bi bi-calendar-event" style="font-size: 2rem; color: orange; float:right;"></i>
                                    </h4>
                                    <h5 class="fw-bolder mb-1">{{FinalPrevisto}}</h5>
                                </div>
                            </div>
                        </div>   
                        <div class="col-lg-4 col-md-3 col-6">
                            <div class="card">
                                <div class="card-body pb-50" style="position: relative;">
                                    <h4 class="fw-bolder mb-1">Aberto em
                                        <i class="bi bi-calendar-event" style="font-size: 2rem; color: green; float:right;"></i>
                                    </h4>
                                    <h5 class="fw-bolder mb-1">{{AbertoEm}}</h5>
                                </div>
                            </div>
                        </div>   
                </div>
                <div class="row match-height">
                    <div class="col-lg-12 col-md-4 col-6">
                        <div class="card">
                            <div class="card-body pb-50" style="position: relative;">
                                <h4 class="fw-bolder mb-1">Descição
                                    <i class="bi bi-clipboard2-check" style="font-size: 2rem; color: orange; float:right;"></i>
                                </h4>
                                <h6 class="fw-bolder mb-1">{{DescricaoOs}}</h6>
                            </div>
                        </div>
                    </div>
                </div>                                                              
                <div class="col-13" ref="tabelaDetalhes">
                    <div class="card mb-4">
                        <div class="card-body pb-60" >
                            <div>
                                <b-table
                                    :items="ListaSubOs"
                                    :fields="ListaSubOsfields"
                                    :sort-by.sync="sortBy"
                                    responsive="sm"
                                    select-mode = "single"
                                    striped
                                    hover
                                    selectable
                                    sticky-header
                                    table-class = "font-size11"
                                    thead-class = "font-size11"
                                    thead-tr-class = "font-size11"
                                >
                                    <template #cell(NumItem)="row">
                                        {{ row.value }}s
                                    </template>

                                    <template #cell(DtAlter)="row">
                                        {{ row.value }}
                                    </template>

                                    <template #cell(Solucao)="row">
                                        {{ row.value }}
                                    </template>
                                </b-table> 
                            </div>
                        </div>
                    </div>
                </div>
                <div class="DivBotaoLadoLado" style="position: relative;left: -.3rem;top: -.3rem">
                    <b-button variant="danger"
                              ype="submit"
                              @click="FecharDetalhes"
                              style="width: 13rem;"
                    ><span>Fechar Detalhes</span>
                    </b-button>
                </div>                               
                <div class="DivBotaoLadoLado" style="position: relative;left: -.3rem;top: -.3rem">
                    <b-button variant="success"
                              ype="submit"
                              @click="$router.push(`../qsAberto/xxeCTK+Agpw=/${NumOsDetalhe}`)"
                              style="width: 13rem;"
                    ><span>Avaliar</span>
                    </b-button>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>

import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import BCardCode from '@core/components/b-card-code'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'


import {
  BFormGroup,
  BRow,
  BCol,
  BFormDatepicker,
  BFormRadio,
  BCalendar,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
} from 'bootstrap-vue'
import { numeric } from 'vee-validate/dist/rules'
import { VueSelect } from 'vue-select'
import moment, { min } from 'moment'
import { splice } from 'postcss-rtl/lib/affected-props'

export default {
    components:{
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BFormDatepicker,
    BFormRadio,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
    BCalendar
},
    directives: { 'b-toggle': VBToggle },
    data(){
        return{
            showFiltro: true,
            RelName: '',   
            RelRota: '',
            FiltroRelatorio: [],
            Periodo:'',
            PeriodoInicio:'',
            PeriodoFim:'',
            ConsidereMeuCR: null,
            TotalHoras: 0,
            TotalOsRotina: 0,
            TotalProjeto: 0,
            DescricaoOs: '',
            NumOsDetalhe: '',
            InicioPrevisto: '',
            FinalPrevisto: '',
            AbertoEm: '',
            OsMesAtual: 0,
            NROS: '',
            NRCR: '',
            selected:'',
            CRopcoes:[],
            OptionsCR: [],
            selectedStatus:{ "name": "Todos", "code": "N" },
            OptionsStatus: [
                { name: 'Todos', code: 'N' },
                { name: 'Abertos', code: 'A' },
                { name: 'Resolvidos', code: 'R' },
                { name: 'Pendente Cliente', code: 'P' },
            ],
            Filtro: '',
            BuscarPorOptions: [
                    { value: 'previsto', text: 'Previsão' },
                    { value: 'aberto', text: 'Abertura' },
                    { value: 'fechamento', text: 'Fechamento' },

            ],
            filtrarPor: 'previsto',
            perPage: 10,
            pageOptions: [3, 5, 10, 50],
            totalRows: 1,
            currentPage: 1,
            sortBy: '',
            sortDesc: false,
            sortDirection: 'asc',

            ortDesc: false,
            ListaOs: [
                {NUMOS: 0},
                {SOLICITANTE: ""},
                {SETOR: ""},
                {DHCHAMADA: ""},
                {AD_DTPREVATI: ""},
                {AD_DTPREVATF: ""},
                {TEMPPREVISTO: ""},
                {ABERTA: ""},
                {PRIORIDADE: 0},
                {STATUS: ""},
                {ATENDENTE: ""},
            ],
            ListaOsfields: [
                { key: 'show_details', label: 'Detalhe', sortable: false },
                { key: 'NUMOS', label: 'OS' ,sortable: true, tdClass: 'td10' },
                { key: 'SOLICITANTE', label: 'SOLICITANTE' ,sortable: true, tdClass: 'td100' },
                { key: 'SETOR', label: 'SETOR' ,sortable: true, tdClass: 'td100' },
                { key: 'STATUS', label: 'STATUS' ,sortable: true , tdClass: 'td100' },
                { key: 'DHCHAMADA', label: 'Data Abertura' ,sortable: true , tdClass: 'td100' },
                { key: 'AD_DTPREVATI', label: 'Data Início' ,sortable: true , tdClass: 'td100' },
                { key: 'AD_DTPREVATF', label: 'Data Final' ,sortable: true , tdClass: 'td100' },
                { key: 'TEMPPREVISTO', label: 'Previsão Entrega' ,sortable: true , tdClass: 'td100' },
                { key: 'ATENDENTE', label: 'ATENDENTE' ,sortable: true , tdClass: 'td100' },
                { key: 'PRIORIDADE', label: 'PRIORIDADE' ,sortable: true , tdClass: 'td100' },
            ],
            ListaSubOs: [
                {NUMITEM: 0},
                {ATENDENTE: ""},
                {SOLUCAO: ""},
            ],
            ListaSubOsfields: [
                { key: 'NUMITEM', label: 'N°Atendimento' ,sortable: true, tdClass: 'td100' },
                { key: 'ATENDENTE', label: 'ATENDENTE' ,sortable: true , tdClass: 'td100' },
                { key: 'SOLUCAO', label: 'SOLUCAO' ,sortable: true, tdClass: 'td100' },
            ],
            chartFatGrupoSetorOptions: { 
                chart: {
                    id: 'GraficoOS'
                },
                xaxis: {
                    categories: [''],//'S&OP', 'Contabil', 'TI', 'Financeiro'
                    labels: {
                        show: true
                    },
                },
            },
            series: []
        }
    },
    methods:{
        async MostraReport() {
            let notification = ""
            Spinner.StarLoad()
            const usuario = this.$session.get('usuario')
            
            let ParametroJson = ""
            let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                    if (response.data != null) {
                        this.RelName = response.data.descricaoRelatorio
                    } else {
                        notification = ConexaoAPI.MsgFiltroSemRetorno()
                        AlertaMsg.FormataMsg(notification)
                    }
                }
            }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })
            if(notification === '') {
                Spinner.EndLoad()
            }
        },
        async ConsultarRelatorio(){
            Spinner.StarLoad()

            this.showFiltro = false

            const usuario = this.$session.get('usuario')
            let notification = ""

            let ParametroJson = ''
            let URLpost = ''

            this.FiltroRelatorio = []

            this.ListaOs = []

            this.TotalHoras = 0
            this.TotalOsRotina = 0
            this.TotalProjeto = 0
            this.OsMesAtual = 0

            this.DescricaoOs = ''
            this.InicioPrevisto = ''
            this.FinalPrevisto = ''
            this.AbertoEm = ''
            this.NumOsDetalhe = 0

            //this.chartFatGrupoSetorOptions.xaxis.categories.splice(0)
            this.series = []

            this.OptionsCR.forEach(Item => {
                const Filtro = {
                parametro: 'CR',
                valor: Item.code,
                }
                this.FiltroRelatorio.push(Filtro)
            })
            ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
            URLpost = `/Report/ExecutaRelAcompanharOsTi?CODUSU=${usuario.codusu}&_data=${this.Periodo}
            &_status=${this.selectedStatus.code}&AD_PROJETO=
            &_NUMOS=${this.NROS}&_Nivel=NV1&_periodoInicio=${this.PeriodoInicio}&_periodoFinal=${this.PeriodoFim}&_filtrarPor=${this.filtrarPor}`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                    if(response.data.length > 0){
                        this.ListaOs = response.data
                        this.TotalHoras = response.data.length > 0 ? response.data[0].HORASTOTAL :0
                        this.OsMesAtual = response.data.length > 0 ? response.data[0].TOTALOSMES :0
                       //console.log(this.ListaOs)
                        this.ListaOs.forEach(Dados => {  
                            if(Dados.PROJETO === 'S'){
                                this.TotalProjeto += 1
                            }else{
                                this.TotalOsRotina += 1
                            }         
                            const setor = Dados.SETOR;

                            // Verifica se já existe o setor na lista 'series'
                            const setorExistente = this.series.find((item) => item.name === setor);

                            if (setorExistente) {
                                // Se já existe, incrementa o valor
                                setorExistente.data[0] += 1;
                            } else {
                                // Se não existe, adiciona um novo item
                                const novoSetor = {
                                    name: setor,
                                    data: [1], // Começa com 1, pois é a primeira ocorrência
                                };
                                this.series.push(novoSetor);
                            }
                        });

                        // Ordena a lista de setores com base na contagem (quantidade de ocorrências)
                        this.series.sort((a, b) => b.data[0] - a.data[0]);

                        // Mantém apenas os 4 primeiros elementos
                        this.series = this.series.slice(0, 4);

                        //console.log(this.series);  
                    }
                }
            }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })

            if(notification === '') {
                setTimeout(() =>{
                    Spinner.EndLoad();
                    this.$refs.Detalhes.style.display = "none";
                },1500);
            }
        },
        async ConsultarRelatorioIniciar(){
            Spinner.StarLoad()

            this.showFiltro = true

            const usuario = this.$session.get('usuario')
            let notification = ""

            let ParametroJson = ''
            let URLpost = ''

            this.FiltroRelatorio = []

            this.ListaOs = []

            this.TotalHoras = 0
            this.TotalOsRotina = 0
            this.TotalProjeto = 0
            this.OsMesAtual = 0

            this.DescricaoOs = ''
            this.InicioPrevisto = ''
            this.FinalPrevisto = ''
            this.AbertoEm = ''
            this.NumOsDetalhe = 0

            this.OptionsCR.forEach(Item => {
                const Filtro = {
                parametro: 'CR',
                valor: Item.code,
                }
                this.FiltroRelatorio.push(Filtro)
            })
            ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
            URLpost = `/Report/ExecutaRelAcompanharOsTi?CODUSU=${usuario.codusu}&_data=${this.Periodo}
            &_filtroCr=${this.FiltroRelatorio}&_status=${this.selectedStatus.code}&AD_PROJETO=
            &_NUMOS=${this.NROS}&_Nivel=NV1&_periodoInicio${this.PeriodoInicio}&_periodoFinal${this.PeriodoFim}&_filtrarPor=${this.filtrarPor}`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                    if(response.data.length > 0){
                        this.ListaOs = response.data
                        this.OsMesAtual = response.data.length > 0 ? response.data[0].TOTALOSMES :0
                        this.TotalHoras = response.data.length > 0 ? response.data[0].HORASTOTAL :0
                        this.ListaOs.forEach(Dados => {  
                            if(Dados.PROJETO === 'S'){
                                this.TotalProjeto += 1
                            }else{
                                this.TotalOsRotina += 1
                            }              
                        });
                    }
                }
            }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })

            if(notification === '') {
                setTimeout(() =>{
                    Spinner.EndLoad();
                    this.$refs.Detalhes.style.display = "none";
                },1000);
            }
        },
        async ConsultarRelatorioOsRotina(){
            Spinner.StarLoad()

            this.showFiltro = false
            const usuario = this.$session.get('usuario')
            let notification = ""

            let ParametroJson = ''
            let URLpost = ''

            this.FiltroRelatorio = []

            this.ListaOs = []

            this.TotalOsRotina = 0

            this.DescricaoOs = ''
            this.InicioPrevisto = ''
            this.FinalPrevisto = ''
            this.AbertoEm = ''
            this.NumOsDetalhe = 0

            this.series = []

            this.OptionsCR.forEach(Item => {
                const Filtro = {
                parametro: 'CR',
                valor: Item.code,
                }
                this.FiltroRelatorio.push(Filtro)
            })
            ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
            URLpost = `/Report/ExecutaRelAcompanharOsTi?CODUSU=${usuario.codusu}&_data=${this.Periodo}
            &_filtroCr=${this.FiltroRelatorio}&_status=${this.selectedStatus.code}&AD_PROJETO=N
            &_NUMOS=${this.NROS}&_Nivel=NV1&_periodoInicio=${this.PeriodoInicio}&_periodoFinal=${this.PeriodoFim}&_filtrarPor=${this.filtrarPor}`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                    if(response.data.length > 0){
                        this.ListaOs = response.data
                        this.TotalHoras = response.data.length > 0 ? response.data[0].HORASTOTAL :0
                        this.ListaOs.forEach(Dados => {  
                            if(Dados.PROJETO === 'S'){
                                this.TotalProjeto += 1
                            }else{
                                this.TotalOsRotina += 1
                            }  
                            const setor = Dados.SETOR;

                            // Verifica se já existe o setor na lista 'series'
                            const setorExistente = this.series.find((item) => item.name === setor);

                            if (setorExistente) {
                                // Se já existe, incrementa o valor
                                setorExistente.data[0] += 1;
                            } else {
                                // Se não existe, adiciona um novo item
                                const novoSetor = {
                                    name: setor,
                                    data: [1], // Começa com 1, pois é a primeira ocorrência
                                };
                                this.series.push(novoSetor);
                            }
                        });

                        // Ordena a lista de setores com base na contagem (quantidade de ocorrências)
                        this.series.sort((a, b) => b.data[0] - a.data[0]);

                        // Mantém apenas os 4 primeiros elementos
                        this.series = this.series.slice(0, 4);
                    }
                }
            }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })

            if(notification === '') {
                setTimeout(() =>{
                    Spinner.EndLoad();
                    this.$refs.Detalhes.style.display = "none";
                },1000);
            }
        },
        async ConsultarRelatorioProjeto(){
            Spinner.StarLoad()

            this.showFiltro = false

            const usuario = this.$session.get('usuario')
            let notification = ""

            let ParametroJson = ''
            let URLpost = ''

            this.FiltroRelatorio = []

            this.ListaOs = []

            this.TotalProjeto = 0

            this.DescricaoOs = ''
            this.InicioPrevisto = ''
            this.FinalPrevisto = ''
            this.AbertoEm = ''
            this.NumOsDetalhe = 0

            this.series = []

            this.OptionsCR.forEach(Item => {
                const Filtro = {
                parametro: 'CR',
                valor: Item.code,
                }
                this.FiltroRelatorio.push(Filtro)
            })
            ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
            URLpost = `/Report/ExecutaRelAcompanharOsTi?CODUSU=${usuario.codusu}&_data=${this.Periodo}
            &_filtroCr=${this.FiltroRelatorio}&_status=${this.selectedStatus.code}&AD_PROJETO=S
            &_NUMOS=${this.NROS}&_Nivel=NV1&_periodoInicio=${this.PeriodoInicio}&_periodoFinal=${this.PeriodoFim}&_filtrarPor=${this.filtrarPor}`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                    //console.log(this.PeriodoFim, this.PeriodoInicio)
                    if(response.data.length > 0){
                        this.ListaOs = response.data
                        this.TotalHoras = response.data.length > 0 ? response.data[0].HORASTOTAL :0
                        this.ListaOs.forEach(Dados => {  
                            if(Dados.PROJETO === 'S'){
                                this.TotalProjeto += 1
                            }else{
                                this.TotalOsRotina += 1
                            }                       
                            const setor = Dados.SETOR;

                            // Verifica se já existe o setor na lista 'series'
                            const setorExistente = this.series.find((item) => item.name === setor);

                            if (setorExistente) {
                                // Se já existe, incrementa o valor
                                setorExistente.data[0] += 1;
                            } else {
                                // Se não existe, adiciona um novo item
                                const novoSetor = {
                                    name: setor,
                                    data: [1], // Começa com 1, pois é a primeira ocorrência
                                };
                                this.series.push(novoSetor);
                            }
                        });

                            // Ordena a lista de setores com base na contagem (quantidade de ocorrências)
                            this.series.sort((a, b) => b.data[0] - a.data[0]);

                            // Mantém apenas os 4 primeiros elementos
                            this.series = this.series.slice(0, 4);
                    }
                }
            }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })

            if(notification === '') {
                setTimeout(() =>{
                    Spinner.EndLoad();
                    this.$refs.Detalhes.style.display = "none";
                },1000);
            }
        },
        async ConsultarDetalhesOs(numos){
            Spinner.StarLoad()

            this.showFiltro = false

            const usuario = this.$session.get('usuario')
            let notification = ""

            let ParametroJson = ''
            let URLpost = ''

            this.FiltroRelatorio = []

            this.ListaSubOs = []
            this.NumOsDetalhe = 0
            this.DescricaoOs = ''

            this.OptionsCR.forEach(Item => {
                const Filtro = {
                parametro: 'CR',
                valor: Item.code,
                }
                this.FiltroRelatorio.push(Filtro)
            })
            ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
            URLpost = `/Report/ExecutaRelAcompanharOsTi?CODUSU=${usuario.codusu}&_data=${this.Periodo}&_status=${this.selectedStatus.code}
            &_AD_PROJETO=&_numOs=${numos}&_Nivel=NV2&_filtrarPor=${this.filtrarPor}`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                    if(response.data.length > 0){
                        this.ListaSubOs = response.data
                        this.TotalHoras = response.data.length > 0 ? response.data[0].HORASTOTAL :0
                        this.DescricaoOs = response.data[0].DESCRICAO.slice(0, 150)+'  ...'
                        this.InicioPrevisto = response.data[0].AD_DTPREVATI
                        this.FinalPrevisto = response.data[0].AD_DTPREVATF
                        this.AbertoEm = response.data[0].DHCHAMADA
                        this.NumOsDetalhe = response.data[0].NUMOS
                    }
                }
            }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })

            if(notification === '') {
                setTimeout(() =>{
                    Spinner.EndLoad();
                    this.$refs.Detalhes.style.display = "block";


                    this.$refs.Detalhes.scrollIntoView({ behavior: 'smooth' });

                    // const detalhesElement = this.$refs.tabelaDetalhes;
                    // const scrollOffset = 100 // Ajuste o valor conforme necessário
                    // detalhesElement.scrollIntoView({ behavior: 'smooth' });
                    // detalhesElement.scrollTop += scrollOffset;

                },1000);
            }
        },
        FecharDetalhes(){
            this.$refs.Detalhes.style.display = "none";
        },
        async ConsultarOpcoes() {
            let notification = ""
            Spinner.StarLoad()
            const usuario = this.$session.get('usuario')
            
            let ParametroJson = ""
            let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=CentroResultado`
            await apiPortalSky.post(URLpost, ParametroJson).then(response => {
                if (response.status === 200) {
                if (response.data.length > 0) {
                    this.CRopcoes = response.data
                } else {
                    notification = ConexaoAPI.MsgFiltroSemRetorno()
                    AlertaMsg.FormataMsg(notification)
                }
                }
            }).catch(error => {
                notification = ConexaoAPI.ValidaErro(error)
                AlertaMsg.FormataMsg(notification)
            })

            if(notification === '') {
            Spinner.EndLoad()
            }
        },
        Grafico(){
            const ctx = document.getElementById('myChart');

            new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
                    datasets: [{
                        label: '# of Votes',
                        data: [12, 19, 3, 5, 2, 3],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });
        }
    },
    mounted() {
    if (this.$route.name != null) {
        this.RelRota = this.$route.name
        this.MostraReport()
        this.ListaOs = []
        //this.ConsultarRelatorioIniciar()
        this.ConsultarOpcoes()
        this.Grafico()

        let now = new Date()
        let today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

        let dia = now.getDate()

        const minDate = new Date(today)
        minDate.setMonth(minDate.getMonth() - 2)
        minDate.setDate(dia)


        const maxDate = new Date(today)
        maxDate.setMonth(maxDate.getMonth())
        maxDate.setDate(dia)

        this.min = minDate
        this.max = maxDate
    

        // :min="min" :max="max" para colocar no input e criar as travas

    }
  },
}
</script>
<style>
    .consulta_card{
        transition-duration: 3s;
        cursor: pointer;
    }
    .consulta_card:hover{
        top: -2px;
    }

    #cardDetails{
        background-color: #2b2a2a;
        opacity: 0.8;
    }
    
    .Detalhes{
        display: none;
        transition: all 3s;
    }
    #infoSpan{
        opacity: 0.5;
        font-size: 0.9rem;
    }
    #selectCR{
        background-color: red;
    }

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<!-- <script>
  export default {
    data() {
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      const minDate = new Date(today)
      minDate.setMonth(minDate.getMonth() - 2)
      minDate.setDate(15)

      const maxDate = new Date(today)

      return {
        value: '',
        min: minDate,
        max: maxDate
      }
    }
  }
</script> -->